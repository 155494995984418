import { makeCreateAction, makeCreateRoutine } from '../../helpers/actions';
import { VerificationStep } from '../interfaces';

const createAction = makeCreateAction('SETTINGS');
const createRoutine = makeCreateRoutine('SETTINGS');

export const verifyPhoneNumber = createRoutine('VERIFY_PHONE_NUMBER', {
  request: (value) => ({ value }),
  fulfill: (response) => ({ response }),
  failure: (error) => ({ error }),
});

export const verifyVerificationCode = createRoutine('VERIFY_VERIFICATION_CODE', {
  request: (value) => ({ value }),
  fulfill: (response) => ({ response }),
  failure: (error) => ({ error }),
});

export const verifyLicenceNumber = createRoutine('VERIFY_LICENCE_NUMBER', {
  request: (value) => ({ value }),
  fulfill: (response) => ({ response }),
  failure: (error) => ({ error }),
});

export const setUser = createAction('SET_USER', (user) => ({ payload: { user } }));
export const logout = createAction('LOGOUT');
export const backToStep = createAction('BACK_TO_STEP');
export const setStep = createAction('SET_STEP', (step: VerificationStep) => ({
  payload: { step },
}));

export const getLyftHeaders = createRoutine('GET_LYFT_HEADERS', {
  success: ({
    accessTokenLicense,
    accessTokenParams,
    accessTokenPhone,
    accountIdentifiers,
    phoneAuth,
    tcsConfig,
  }) => ({
    accessTokenLicense,
    accessTokenParams,
    accessTokenPhone,
    accountIdentifiers,
    phoneAuth,
    tcsConfig,
  }),
  failure: (error) => ({ error }),
});

export const getLyftAuthAccessToken = createRoutine('GET_LYFT_AUTH_ACCESS_TOKEN', {
  fulfill: (response) => ({ response }),
  success: ({ token_type, access_token }) => ({ token_type, access_token }),
});

export const sendTcsConfig = createRoutine('SEND_TCS_CONFIG');

export const actionCreators = {
  setUser,
  logout,
  verifyPhoneNumber,
  verifyVerificationCode,
  verifyLicenceNumber,
  backToStep,
  setStep,
  getLyftHeaders,
  getLyftAuthAccessToken,
  sendTcsConfig,
} as const;

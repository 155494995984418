import React from 'react';

import { sendEvent } from '../../../services/reactGa';
import { settingsRecommendationsService } from '../../../services/SettingsRecommendationsService';
import { useTranslation } from '../../../helpers/hooks';
import { CarouselItem } from '../../../common/Carousel';
import { useToggleModalMap } from '../hooks';

export const PushPermission = React.memo(() => {
  const toggleModalsMap = useToggleModalMap();
  const { t } = useTranslation();

  return (
    <CarouselItem
      onMount={() => sendEvent('DriverRecommendations_NotificationPermissionRequest_Show')}
      onClose={() => {
        toggleModalsMap({ pushPermission: false });
        sendEvent('DriverRecommendations_NotificationPermissionRequest_RemindLater');
      }}
      onApply={() => {
        sendEvent('DriverRecommendations_NotificationPermissionRequest_Apply');
        settingsRecommendationsService.askForPermission('notification');
        toggleModalsMap({
          pushPermission: false,
        });
      }}
      applyText={t('modals.recommendations.pushPermission.applyButton')}
      cancelText={t('modals.recommendations.pushPermission.cancelButton')}
    >
      <div dangerouslySetInnerHTML={{ __html: t('modals.recommendations.pushPermission.body') }} />
    </CarouselItem>
  );
});

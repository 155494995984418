import React from 'react';
import styled from 'styled-components';

import { Header } from '../onboarding/styled';
import { CloseButton } from '../../../common/CloseButton';
import { sendEvent } from '../../../services/reactGa';
import { vh100 } from '../../../config/css';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  // height: calc(${vh100});
  overflow: hidden;
  padding: 0 20px 20px 20px;

  div:first-child {
    flex: 0;
  }

  div:last-child {
    flex: 1;
    overflow: auto;
    font-size: 18px !important;
  }
`;

interface PrivacyPolicyProps {
  handleClose: () => void;
}

export const PrivacyPolicy: React.FC<PrivacyPolicyProps> = ({ handleClose }) => {
  return (
    <Container>
      <Header>
        <h2>Privacy policy</h2>
        <CloseButton
          onClick={() => {
            sendEvent('StepByStepSlider_CloseClick');
            handleClose();
          }}
        />
      </Header>

      <div>
        <h3>Introduction</h3>
        Skeddy Technologies Inc. ("Skeddy," "we," "us," or "our") respects your privacy and is
        committed to protecting your personal data. This Privacy Policy explains how we collect,
        use, disclose, and safeguard your information when you use our mobile application ("App")
        available on the Google Play and Apple App Stores.
        <h3>Information We Collect</h3>
        <div>
          We collect the following personal data from users:
          <ul>
            <li>
              <strong>Contact Information</strong>: Name, email address, phone number.
            </li>
            <li>
              <strong>Usage Data</strong>: Information about your interactions with our App, such as
              the features you use and the time you spend on the App.
            </li>
            <li>
              <strong>Background Location Data</strong>: Real-time location of your device.
            </li>
          </ul>
        </div>
        <div>
          <h3>Why We Collect Background Location Data</h3>
          Skeddy collects background location data to enhance Lyft drivers' driving experience. Our
          app requires access to your location in the background to provide you with the following
          core features:
          <ul>
            <li>
              <strong>Real-time Ride Filtering</strong>: Skeddy suggests the best upcoming scheduled
              rides based on your current location, even when the app is not in use. This ensures
              you receive timely and relevant ride notifications, helping you maximize your
              efficiency and earnings.
            </li>
            <li>
              <strong>Route Optimization</strong>: By continuously updating your location, Skeddy
              can provide optimized routes and timely alerts for ride opportunities, reducing
              downtime and unnecessary travel.
            </li>
          </ul>
        </div>
        <div>
          <h3>How We Use Your Information</h3>
          We use the information we collect for the following purposes:{' '}
          <ul>
            <li>
              <strong>Account Creation and Maintenance</strong>: To create and manage your account
              on our App.
            </li>
            <li>
              <strong>Service Delivery</strong>: To provide you with the services you request, such
              as scheduling rides.
            </li>
            <li>
              <strong>Improvement of Services</strong>: To understand how you use our App and to
              improve its functionality.
            </li>
            <li>
              <strong>Communication</strong>: To send you updates, notifications, and other
              information related to your use of the App.
            </li>
            <li>
              <strong>Legal Compliance</strong>: To comply with legal obligations and to protect our
              legal rights.
              <br />
            </li>
          </ul>
        </div>
        <div>
          <h3>Sharing Your Information</h3>
          We may share your personal data with:
          <ul>
            <li>
              <strong>Service Providers</strong>: Third-party service providers that assist us in
              operating our App and providing our services.
            </li>
            <li>
              <strong>Legal Authorities</strong>: When required by law or to protect our legal
              rights.
            </li>
            <li>
              <strong>Business Transfers</strong>: In connection with a merger, acquisition, or sale
              of assets.
            </li>
          </ul>
        </div>
        <div>
          <h3>Security</h3>
          We implement appropriate technical and organizational measures to protect your personal
          data against unauthorized access, alteration, disclosure, or destruction.
        </div>
        <div>
          <h3>Your Rights</h3>
          You have the following rights regarding your personal data:{' '}
          <ul>
            <li>
              <strong>Access</strong>: Request access to the personal data we hold about you.
            </li>
            <li>
              <strong>Correction</strong>: Request correction of incorrect or incomplete personal
              data.
            </li>
            <li>
              <strong>Deletion</strong>: Request deletion of your personal data.
            </li>
            <li>
              <strong>Objection</strong>: Object to the processing of your personal data.
            </li>
            <li>
              <strong>Data Portability</strong>: Request transfer of your personal data to another
              service provider.
            </li>
          </ul>
        </div>
        <div>
          <h3>Changes to This Privacy Policy</h3>
          We may update this Privacy Policy from time to time. We will notify you of any changes by
          posting the new Privacy Policy on our App. You are advised to review this Privacy Policy
          periodically for any changes.
        </div>
      </div>
    </Container>
  );
};

export function debounce(func: (...rest: any[]) => void, timeout = 300) {
  let timer;

  return function (...rest: any[]): void {
    clearTimeout(timer);

    timer = setTimeout(() => {
      func(...rest);
    }, timeout);
  };
}

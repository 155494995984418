import { createReducer } from '@reduxjs/toolkit';

import { isOneOf } from '../../helpers/actions';
import { VerificationReducerState, VerificationStep } from '../interfaces';

import { actionCreators } from './actions';

const defaultVerification: VerificationReducerState = {
  license_number: null,
  phone_number: null,
  verification_code: null,

  isLoading: false,
  error: null,
  step: VerificationStep.phoneNumber,

  authHeaders: {
    accessTokenLicense: null,
    accessTokenParams: null,
    accessTokenPhone: null,
    accountIdentifiers: null,
    phoneAuth: null,
    tcsConfig: null,
  },

  token_type: null,
  access_token: null,

  isLyftParamsLoading: false,
  isLyftParamsError: false,
};

const initialState: Readonly<VerificationReducerState> = {
  ...defaultVerification,
};

const verificationReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(actionCreators.getLyftHeaders.REQUEST, (state) => {
      state.isLyftParamsLoading = true;
      state.isLyftParamsError = false;

      state.token_type = null;
      state.access_token = null;
    })

    .addCase(actionCreators.getLyftHeaders.SUCCESS, (state, action) => {
      const {
        accessTokenLicense,
        accessTokenParams,
        accessTokenPhone,
        accountIdentifiers,
        phoneAuth,
        tcsConfig,
      } = action.payload;

      state.isLyftParamsError = false;
      state.authHeaders = {
        accessTokenLicense,
        accessTokenParams,
        accessTokenPhone,
        accountIdentifiers,
        phoneAuth,
        tcsConfig,
      };
    })

    .addCase(actionCreators.getLyftHeaders.FAILURE, (state) => {
      state.isLyftParamsLoading = false;
      state.isLyftParamsError = true;
    })

    .addCase(actionCreators.getLyftAuthAccessToken.REQUEST, (state) => {
      state.isLyftParamsLoading = true;
      state.isLyftParamsError = false;
    })

    .addCase(actionCreators.getLyftAuthAccessToken.SUCCESS, (state, action) => {
      const { token_type, access_token } = action.payload;

      state.isLyftParamsLoading = false;
      state.isLyftParamsError = false;
      state.token_type = token_type;
      state.access_token = access_token;
    })

    .addCase(actionCreators.getLyftAuthAccessToken.FAILURE, (state) => {
      state.isLyftParamsLoading = false;
      state.isLyftParamsError = true;
    })

    .addCase(actionCreators.verifyPhoneNumber.REQUEST, (state, action) => {
      const { value } = action.payload;

      state.phone_number = value;
      state.isLoading = true;
    })

    .addCase(actionCreators.verifyVerificationCode.REQUEST, (state, action) => {
      const { value } = action.payload;

      state.verification_code = value;
      state.isLoading = true;
    })

    .addCase(actionCreators.verifyLicenceNumber.REQUEST, (state, action) => {
      const { value } = action.payload;

      state.license_number = value;
      state.isLoading = true;
    })

    .addCase(actionCreators.verifyLicenceNumber.SUCCESS, (state) => {
      Object.entries(defaultVerification).forEach(([key, value]) => {
        state[key] = value;
      });
    })

    .addCase(actionCreators.setStep, (state, action) => {
      const { step } = action.payload;

      state.step = step;
      state.error = null;
      state.isLoading = false;
    })

    .addCase(actionCreators.backToStep, (state) => {
      state.step = VerificationStep.phoneNumber;

      state.isLoading = false;
      state.verification_code = null;
      state.license_number = null;
      state.error = null;
    })

    .addMatcher(
      isOneOf([
        actionCreators.verifyPhoneNumber.FAILURE,
        actionCreators.verifyVerificationCode.FAILURE,
        actionCreators.verifyLicenceNumber.FAILURE,
      ]),
      (state, action) => {
        const { error } = action.payload;
        state.error = error;
        state.isLoading = false;
      }
    )
);

export default verificationReducer;

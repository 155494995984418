import React from 'react';
import { RouteProps } from 'react-router-dom';

import urls from 'config/urls';

import { importRetry } from './helpers/importRetry';

export type AppRouteProps = RouteProps & {
  component: any;
  path: string;
};

const Map = React.lazy(() => importRetry(() => import('core/map')));

const Rides = React.lazy(() => importRetry(() => import('core/rides')));
const Debug = React.lazy(() => importRetry(() => import('core/debug')));
const Settings = React.lazy(() => importRetry(() => import('core/settings')));
const Start = React.lazy(() => importRetry(() => import('core/pages/start')));
const Credits = React.lazy(() => importRetry(() => import('core/credits')));
const Verification = React.lazy(() => importRetry(() => import('core/verification')));
const AddRide = React.lazy(() => importRetry(() => import('core/addRide')));
const AdminLogin = React.lazy(() => importRetry(() => import('core/adminLogin')));

export const driverRoutes: AppRouteProps[] = [
  {
    component: Map,
    path: urls.map,
  },
  {
    component: Rides,
    path: urls.rides,
  },
  {
    component: Settings,
    path: urls.settings,
  },
  {
    component: Credits,
    path: urls.credits,
  },
  {
    component: Credits,
    path: urls.creditsHistory,
  },
  {
    component: Debug,
    path: urls.debug,
  },
  {
    component: Credits,
    path: urls.creditsRules,
  },
  {
    component: AdminLogin,
    path: urls.adminLogin,
  },
  {
    component: AddRide,
    path: urls.addRide,
  },
];

export const guestRoutes: AppRouteProps[] = [
  {
    component: Start,
    path: urls.start,
  },
  {
    component: Verification,
    path: urls.login,
  },
  {
    component: AdminLogin,
    path: urls.adminLogin,
  },
];

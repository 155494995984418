import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'helpers/hooks';

import { Button, Container } from '../../../common/styled/form';
import GuestLayout from '../../../app/Layout/GuestLayout';
import { getAppStoreUrl } from '../../../config/urls';
import { Link } from '../../../common/Link';
import { isIos } from '../../../helpers/os';
import { SkeddyImage } from '../../../common/Carousel';
import { sendEvent } from '../../../services/reactGa';
import { AppState } from '../../../store/interfaces';
import { setShowUpdatePage } from '../../../store/app/acions';

import { MainContainer, MainText, Title, AppLinks, LayoutWrapper } from './styled';

const url = getAppStoreUrl();

const UpdatePage: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const showUpdatePage = useSelector((appState: AppState) => appState.app.showUpdatePage);
  const dispatch = useDispatch();

  useEffect(() => {
    if (showUpdatePage) {
      sendEvent('UpdatePage_PageView');
    }
  }, [showUpdatePage]);

  const handleGoToShore = () => {
    sendEvent('UpdatePage_ApplyClick');
  };

  const handleClose = () => {
    sendEvent('UpdatePage_CloseClick');
    dispatch(setShowUpdatePage(false));
  };

  if (isIos) {
    return null;
  }

  if (!showUpdatePage) {
    return null;
  }

  return (
    <LayoutWrapper style={{ position: 'absolute', top: 0, zIndex: 100 }}>
      <GuestLayout style={{ backgroundSize: '0' }} allowToDrivers>
        <MainContainer>
          <Container>
            <Title>{t('update.title')}</Title>
            <MainText>{t('update.body')}</MainText>
            <AppLinks>
              <Link onClick={handleGoToShore} target="_blank" href={url}>
                <Button>{t('update.button')}</Button>
              </Link>
              <Link onClick={handleClose} color="#FF00ED">
                {t('update.remindMeLater')}
              </Link>
            </AppLinks>
            <div style={{ marginTop: 'auto', textAlign: 'center' }}>
              <SkeddyImage />
            </div>
          </Container>
        </MainContainer>
      </GuestLayout>
    </LayoutWrapper>
  );
});

export default UpdatePage;

import { AppReducerState, Purchase, PurchaseProducts, WizardStep } from '../interfaces';
import { makeCreateAction, makeCreateRoutine } from '../../helpers/actions';

const createAction = makeCreateAction('APP');
const createRoutine = makeCreateRoutine('APP');

export const setSearchStatus = createRoutine('SET_SEARCH_STATUS', {
  request: (isSearchOn: boolean) => ({ isSearchOn }),
  success: (isSearchOn: boolean) => ({ isSearchOn }),
  failure: (errors) => ({ errors }),
});

export const agreedNewRides = createRoutine('AGREED_NEW_RIDES');

export const getSearchStatus = createRoutine('GET_SEARCH_STATUS', {
  success: (
    isSearchOn: boolean,
    rideCredits: number,
    isAgreed: boolean,
    searchErrorStatus: number
  ) => ({
    isSearchOn,
    rideCredits,
    isAgreed,
    searchErrorStatus,
  }),
  failure: (errors) => ({ errors }),
});

export const setHeaderTitle = createAction('SET_HEADER_TITLE', (title = '') => ({
  payload: {
    title,
  },
}));

export const setBackgroundLocation = createAction(
  'SET_BACKGROUND_LOCATION',
  ({
    lat,
    lng,
    sendStatusToNative = true,
  }: {
    lat: number;
    lng: number;
    sendStatusToNative?: boolean;
  }) => ({
    payload: {
      lat,
      lng,
      sendStatusToNative,
    },
  })
);

export const toggleTheme = createAction('TOGGLE_THEME');

export const toggleShowAllRides = createAction('TOGGLE_SHOW_ALL_RIDES');

export const toggleHelpScreen = createAction('TOGGLE_HELP_SCREEN');

export const toggleSidebar = createAction('TOGGLE_SIDEBAR');

export const toggleShowRideShare = createAction('TOGGLE_SHOW_RIDE_SHARE');

export const setRideCreditsAlert = createAction<{
  value: AppReducerState['rideCreditsAlert'];
}>('SET_RIDE_CREDITS_ALERT', (value) => ({
  payload: { value },
}));

export const setRideCreditsLoading = createAction(
  'SET_RIDE_CREDITS_LOADING',
  (isRideCreditsLoading: boolean) => ({ payload: { isRideCreditsLoading } })
);

export const setPurchaseProducts = createAction(
  'SET_PURCHASE_PRODUCTS',
  (products: PurchaseProducts) => ({ payload: { products } })
);

export const updateRideCredits = createAction('UPDATE_RIDE_CREDITS', (rideCredits: number) => ({
  payload: { rideCredits },
}));

export const validatePurchaseProduct = createAction(
  'VALIDATE_PURCHASE_PRODUCT',
  (purchase: Purchase) => ({ payload: { purchase } })
);

export const setIsReviewApp = createAction('SET_IS_REVIEW_APP', (isReviewApp: boolean) => ({
  payload: { isReviewApp },
}));

export const handleAppVersion = createAction('HANDLE_APP_VERSION', (appVersion: string) => ({
  payload: { appVersion },
}));

export const setShowUpdatePage = createAction(
  'SET_SHOW_UPDATE_PAGE',
  (showUpdatePage: boolean) => ({
    payload: { showUpdatePage },
  })
);

export const sendLog = createAction('SEND_LOG', (data: object) => ({ payload: { data } }));

export const setWizardStep = createAction('SET_WIZARD_STEP', (wizardStep: WizardStep) => ({
  payload: { wizardStep },
}));

export const checkSearchAndRunCallback = createAction<{
  callback: (...args: any) => void;
}>('CHECK_SEARCH_AND_RUN_CALLBACK', (callback) => ({
  payload: { callback },
}));

export const actionCreators = {
  setBackgroundLocation,
  setHeaderTitle,
  setSearchStatus,
  agreedNewRides,
  getSearchStatus,
  toggleHelpScreen,
  toggleTheme,
  toggleSidebar,
  setAppProducts: setPurchaseProducts,
  updateRideCredits,
  validatePurchaseProduct,
  setRideCreditsAlert,
  setRideCreditsLoading,
  toggleShowRideShare,
  toggleShowAllRides,
  setWizardStep,
  checkSearchAndRunCallback,
  sendLog,
  setIsReviewApp,
  handleAppVersion,
  setShowUpdatePage,
} as const;

import { useTranslation as useTranslationBase } from 'react-i18next';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { EnvParam, envService } from '../services/EnvService';
import { AppState } from '../store/interfaces';

type Params = {
  appDep?: boolean | undefined;
} & {
  [key: string]: string | boolean;
};

export const useTranslation = () => {
  const { t, i18n } = useTranslationBase();
  const isReviewApp = useSelector((app: AppState) => app.app.isReviewApp);

  const tWithApp = useCallback(function (message, params: Params = {}) {
    const { appDep, ...rest } = params;
    const isIosMessage = appDep || (appDep === false && isReviewApp);
    return t(
      `${message}${isIosMessage ? 'IOS' : ''}`,
      Object.assign(rest, { appName: envService.getParam(EnvParam.APP_NAME) })
    );
  }, []);

  return { t: tWithApp, i18n };
};

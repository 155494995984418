import styled, { css } from 'styled-components';
import ReactDOM from 'react-dom';
import React, { CSSProperties, useEffect } from 'react';

import GuestLayout from '../../app/Layout/GuestLayout';
import { Button } from '../styled/form';

interface CarouselItemProps {
  onApply(): void;
  onClose?(): void;
  onMount?(): void;
  children?: React.ReactNode | React.ReactNode[];
  hint?: React.ReactNode | React.ReactNode[];
  applyText: string;
  cancelText?: string;
  showImage?: boolean;
  title?: string;
  bodyWrapper?: React.FC;
  styles?: {
    title?: CSSProperties;
    body?: CSSProperties;
    button?: CSSProperties;
    hint?: CSSProperties;
  };
}

interface ContainerProps {
  isCenter: boolean;
}

const Container = styled.div<ContainerProps>`
  text-align: center;
  padding: 56px 40px 0;
  max-width: 380px;
  margin: 0 auto;
  font-size: 24px !important;
  overflow: auto;

  ${(props) => {
    if (props?.isCenter) {
      return css`
        position: relative;
        top: 50%;
        transform: translate(0, -50%);
      `;
    }

    return '';
  }}

  p {
    margin: 0;
  }

  color: ${(props) => props.theme.altText};

  button {
    font-size: 24px !important;
    text-align: center;
    display: inline-block;
  }

  span {
    color: #ec42ce;
  }
`;

const Title = styled.div`
  margin-bottom: 48px;
  font-weight: bold;
`;

const ImgContainer = styled.div`
  width: auto;
  height: 175px;
  margin: 0 auto 40px;

  img {
    margin-left: 20px;
  }
`;

const Body = styled.div`
  margin: 22px 0 40px;
  font-weight: bold;
`;

const CloseLink = styled.span`
  margin-top: 24px;
  display: inline-block;
  text-decoration: underline;
  color: #ec42ce;
  font-size: 19px;
`;

export function SkeddyImage() {
  return (
    <ImgContainer>
      <img
        style={{ maxHeight: 175 }}
        src="/assets/onboarding/community_rock_the_road.svg"
        alt="fb group"
      />
    </ImgContainer>
  );
}

export const CarouselItem: React.FC<CarouselItemProps> = function ({
  onApply,
  applyText,
  onClose,
  cancelText,
  children,
  title,
  hint = null,
  showImage = true,
  onMount,
  styles = { title: {}, body: {}, hint: {}, button: {} },
  bodyWrapper: BodyWrapperComponent,
}) {
  useEffect(() => {
    if (onMount) {
      onMount();
    }
  }, []);

  const BodyWrapper = BodyWrapperComponent ?? React.Fragment;

  return (
    <Container isCenter={children === undefined}>
      {title && <Title style={styles.title} dangerouslySetInnerHTML={{ __html: title }} />}
      {showImage && <SkeddyImage />}
      {children && (
        <BodyWrapper>
          <Body style={styles.body}>{children}</Body>
        </BodyWrapper>
      )}
      {onApply && (
        <Button style={styles.button} onClick={onApply}>
          {applyText}
        </Button>
      )}
      {hint && <div style={styles.hint}>{hint}</div>}
      {onClose && (
        <div>
          <CloseLink onClick={onClose}>{cancelText}</CloseLink>
        </div>
      )}
    </Container>
  );
};

const modalRoot = document.getElementById('modal-helper');

export const Carousel = function ({ isBigLogo = false, zIndex = 100, children }) {
  return ReactDOM.createPortal(
    <GuestLayout
      style={{
        backgroundSize: `${isBigLogo ? 30 : 20}vh auto`,
        position: 'absolute',
        zIndex,
        top: 0,
        width: '100%',
        display: 'block',
        maxWidth: '100%',
      }}
      showLogo
      allowToDrivers
    >
      {children}
    </GuestLayout>,
    modalRoot!
  );
};

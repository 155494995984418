import React, { useEffect } from 'react';
import styled from 'styled-components';

import { useTranslation } from '../../../helpers/hooks';
import GuestLayout from '../../../app/Layout/GuestLayout';
import { Link } from '../../../common/Link';
import { SettingsRecommendationsReducerState } from '../../../store/interfaces';
import { CarouselItem, SkeddyImage } from '../../../common/Carousel';
import { Hint } from '../../../app/Wizard/styled';
import { nativeMessageService } from '../../../services/NativeMessageService';
import { ColorContainer } from '../help/styled';
import { sendEvent } from '../../../services/reactGa';
import { capitalizeFirstLetter } from '../../../common/capitalizeFirstLetter';
import { vh100 } from '../../../config/css';

import { PrivacyPolicy } from './PrivacyPolicy';

const BodyWrapper = styled.div`
  color: ${(props) => props.theme.textColor};
`;

export const MainContainer = styled.div`
  overflow: hidden;
  height: calc(${vh100});

  > div {
    padding: 20px;
    height: 100%;
    box-sizing: border-box;
    font-weight: normal;

    :first-child {
      font-size: 24px;
    }

    > div:first-child {
      font-size: 24px;
      font-weight: bold;
    }

    > div {
      font-weight: normal;
      font-size: 18px;
    }

    > button {
      margin-bottom: 0 !important;
      font-size: 20px !important;
    }
  }
`;

export const PrivacyContainer = styled.div`
  color: ${(props) => props.theme.textColor};
  margin-top: 20px;

  h3 {
    margin-top: 0;
    margin-bottom: 16px;
    color: ${(props) => props.theme.altText};
  }

  margin-bottom: 20px;
`;

const styles = {
  title: {
    marginBottom: 20,
  },
  body: {
    marginBottom: 30,
  },
  button: {
    marginBottom: 50,
  },
};

interface PermissionPageProps {
  permission: keyof SettingsRecommendationsReducerState['permissions'];
  zendeskLink: string;
  withPrivacy?: boolean;
}

const PermissionPage: React.FC<PermissionPageProps> = React.memo(
  ({ permission, zendeskLink, withPrivacy = false }) => {
    const { t } = useTranslation();
    const [showPrivacyPolicyPage, setShowPrivacyPolicyPage] = React.useState(false);

    const pageName = `${capitalizeFirstLetter(permission)}Page`;
    const localePrefix = `${permission}Permission`;
    const handleToggleModal = () => setShowPrivacyPolicyPage(!showPrivacyPolicyPage);

    useEffect(() => {
      sendEvent(`${pageName}_PageView`);
    }, []);

    return (
      <ColorContainer style={{ zIndex: 1000, top: 0 }}>
        <GuestLayout style={{ backgroundSize: '25vh auto' }} showLogo={false} allowToDrivers>
          <MainContainer>
            {showPrivacyPolicyPage ? (
              <PrivacyPolicy handleClose={handleToggleModal} />
            ) : (
              <CarouselItem
                title={t(`${localePrefix}.title`)}
                onApply={() => {
                  sendEvent(`${pageName}_GoToSettingsClick`);
                  nativeMessageService.sendRequestPermissionMessage(permission);
                }}
                applyText={t(`${localePrefix}.goToSettings`)}
                showImage={false}
                bodyWrapper={BodyWrapper}
                styles={styles}
                hint={
                  <>
                    <Hint>
                      {t(`${localePrefix}.hint`)}{' '}
                      <Link
                        target="_blank"
                        onClick={() => sendEvent(`${pageName}_ZendeskClick`)}
                        href={zendeskLink}
                        color="#FF00ED"
                      >
                        {t(`${localePrefix}.here`)}
                      </Link>
                      .
                    </Hint>
                    <br />
                    <br />
                    <br />
                    <SkeddyImage />
                    {withPrivacy && (
                      <PrivacyContainer>
                        <h3>{t(`${localePrefix}.privacy.title`)}:</h3>
                        <div>
                          {t(`${localePrefix}.privacy.body`)}{' '}
                          <Link color="#FF00ED" onClick={handleToggleModal}>
                            {t(`${localePrefix}.privacy.link`)}
                          </Link>
                          .
                        </div>
                      </PrivacyContainer>
                    )}
                  </>
                }
              >
                {t(`${localePrefix}.body`)}
              </CarouselItem>
            )}
          </MainContainer>
        </GuestLayout>
      </ColorContainer>
    );
  }
);

export default PermissionPage;

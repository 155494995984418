import React from 'react';
import { useSelector } from 'react-redux';

import {
  AppState,
  PermissionStatus,
  SettingsRecommendationsReducerState,
} from '../../../store/interfaces';
import { isIos } from '../../../helpers/os';

import PermissionPage from './PermissionPage';

const Permissions: React.FC = React.memo(() => {
  const permissions = useSelector((app: AppState) => app.settingsRecommendations.permissions);
  const isReviewApp = useSelector((app: AppState) => app.app.isReviewApp);
  const user = useSelector((app: AppState) => app.auth.user);

  const showPermissionPage = (
    permission: keyof SettingsRecommendationsReducerState['permissions']
  ) => {
    if (permissions[permission] === PermissionStatus.Undefined) {
      return false;
    }

    return permissions[permission] !== PermissionStatus.Always;
  };

  /* if (isReviewApp) {
    return null;
  } */

  if (!user) {
    return null;
  }

  if (isIos) {
    return null;
  }

  if (showPermissionPage('battery')) {
    return (
      <PermissionPage
        permission="battery"
        zendeskLink="https://skeddy.zendesk.com/hc/en-us/articles/20552666852503"
      />
    );
  }

  /* if (showPermissionPage('notification')) {
    return (
      <PermissionPage
        permission="notification"
        zendeskLink="https://skeddy.zendesk.com/hc/en-us/articles/20552666852503"
      />
    );
  }

  if (showPermissionPage('location')) {
    return (
      <PermissionPage
        permission="location"
        withPrivacy
        zendeskLink="https://skeddy.zendesk.com/hc/en-us/articles/25287307560855"
      />
    );
  } */

  return null;
});

export default Permissions;

import React, { useState, useEffect, useMemo } from 'react';

import { useTranslation } from 'helpers/hooks';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import SwiperClass from 'swiper/types/swiper-class';

import GuestLayout from 'app/Layout/GuestLayout';
import { CloseButton } from 'common/CloseButton';
import { fbLink } from 'config/urls';
import { Link } from 'common/Link';

import { registerStepByStepFbGroupClickEvent, sendEvent } from '../../../services/reactGa';

import Slide from './slide';
import {
  MainContainer,
  SlidesContainer,
  Circle,
  CirclesContainer,
  CirclesWrap,
  SlidesWrap,
  MainText,
  Header,
  SkipButton,
} from './styled';
import { Navigation, NavigationContainer } from './slide/styled';
import { FbText } from './FbText';

interface OnBoardingProps {
  onDoneClick(): void;
  skipAtAnyTime: boolean;
}

const SliderBullets = function ({ controller, speed = 1000 }) {
  const { realIndex, slides } = controller;
  const [currentIndex, setCurrentIndex] = useState(realIndex);
  const onChangeSlide = (swiper) => {
    setCurrentIndex(swiper.realIndex);
  };

  useEffect(() => {
    controller.on('slideChange', onChangeSlide);
    return () => {
      controller.off('slideChange', onChangeSlide);
    };
  }, [controller]);

  const dots: JSX.Element[] = [];
  for (let index = 0; index < slides.length; index++) {
    dots.push(
      <Circle
        onClick={() => {
          sendEvent('StepByStepSlider_DotClick');
          controller.slideTo(index, speed);
        }}
        key={`${index}-${currentIndex}`}
        selected={index === currentIndex}
      />
    );
  }
  const slidesCount = slides.length - 1;
  return (
    <>
      <Navigation
        style={{ marginRight: 5 }}
        onClick={() => {
          if (currentIndex !== 0) {
            sendEvent('StepByStepSlider_LeftClick');
            controller.slideTo(currentIndex - 1, speed);
          }
        }}
      >
        {currentIndex !== 0 && <img alt="left" src="/assets/images/left.png" />}
      </Navigation>
      <CirclesContainer>
        <CirclesWrap>{dots}</CirclesWrap>
      </CirclesContainer>
      <Navigation
        style={{ marginLeft: 5 }}
        onClick={() => {
          if (currentIndex !== slidesCount) {
            sendEvent('StepByStepSlider_RightClick');
            controller.slideTo(currentIndex + 1, speed);
          }
        }}
      >
        {currentIndex !== slidesCount && <img alt="right" src="/assets/images/right.png" />}
      </Navigation>
    </>
  );
};

const OnBoarding: React.FC<OnBoardingProps> = function ({
  onDoneClick,
  skipAtAnyTime,
}: OnBoardingProps) {
  const { t } = useTranslation();
  const [controlledSwiper, setControlledSwiper] = useState<SwiperClass>();
  const [isEnd, setIsEnd] = useState<boolean>(false);

  const slides: JSX.Element[] = useMemo(() => {
    return [
      <SwiperSlide key="2-slide">
        <Slide img="/assets/onboarding/2.png">
          <MainText>{t('onBoarding.slides.1')}</MainText>
        </Slide>
      </SwiperSlide>,
      <SwiperSlide key="3-slide">
        <Slide img="/assets/onboarding/3.png">
          <MainText>{t('onBoarding.slides.2')}</MainText>
        </Slide>
      </SwiperSlide>,
      <SwiperSlide key="4-slide">
        <Slide img="/assets/onboarding/4.png">
          <MainText>{t('onBoarding.slides.3')}</MainText>
        </Slide>
      </SwiperSlide>,
      <SwiperSlide key="5-slide">
        <Slide img="/assets/onboarding/5.png">
          <MainText>{t('onBoarding.slides.4')}</MainText>
        </Slide>
      </SwiperSlide>,
      <SwiperSlide key="6-slide">
        <Slide img="/assets/onboarding/6.png">
          <MainText>{t('onBoarding.slides.5')}</MainText>
        </Slide>
      </SwiperSlide>,
      <SwiperSlide key="7-slide">
        <Slide
          beforeImage={<FbText />}
          imgComponent={
            <Link
              style={{ display: 'inline-block' }}
              onClick={registerStepByStepFbGroupClickEvent}
              href={fbLink}
            >
              <img alt="fb-group" className="fb-image" src="/assets/onboarding/faces.png" />
            </Link>
          }
        >
          <MainText>
            {t('onBoarding.slides.6')}
            <div>
              <img
                className="fb-group-image"
                src="/assets/onboarding/community_rock_the_road.svg"
                alt="fb group"
              />
            </div>
          </MainText>
        </Slide>
      </SwiperSlide>,
    ];
  }, []);

  useEffect(() => {
    sendEvent('StepByStepSlider_PageView');
  }, []);

  useEffect(() => {
    if (!controlledSwiper) {
      return;
    }

    const onChangeSlide = () => {
      setIsEnd(!!controlledSwiper?.isEnd);
    };

    controlledSwiper.on('slideChange', onChangeSlide);

    return () => {
      controlledSwiper.off('slideChange', onChangeSlide);
    };
  }, [controlledSwiper]);

  return (
    <GuestLayout showLogo={false} allowToDrivers>
      <MainContainer>
        <Header>
          <h2>{t('onBoarding.title')}</h2>
          {skipAtAnyTime && (
            <CloseButton
              onClick={() => {
                sendEvent('StepByStepSlider_CloseClick');
                onDoneClick();
              }}
            />
          )}
        </Header>
        <SlidesContainer>
          <SlidesWrap>
            <Swiper onSwiper={setControlledSwiper}>{slides}</Swiper>
          </SlidesWrap>
        </SlidesContainer>
        {controlledSwiper && (
          <NavigationContainer>
            <SliderBullets controller={controlledSwiper} />
            {!skipAtAnyTime && isEnd && (
              <SkipButton
                onClick={() => {
                  sendEvent('StepByStepSlider_DoneClick');
                  onDoneClick();
                }}
              >
                {t('onBoarding.skip')}
              </SkipButton>
            )}
          </NavigationContainer>
        )}
      </MainContainer>
    </GuestLayout>
  );
};

export default OnBoarding;

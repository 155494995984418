import Timer from 'helpers/Timer';

import api from '../../api';
import { Credit } from '../../types/credit';
import { createDebug } from '../../helpers/debug';
import { PermissionStatus } from '../../store/interfaces';
import { StorageKeys, StorageService, storageService } from '../StorageService';
import {
  nativeMessageService,
  NativeMessageService,
  RequestPermissionMessage,
} from '../NativeMessageService';
import { config } from '../Config';

import { RunParams, SettingsRecommendationsMap } from './interfaces';

const debug = createDebug.extend('settingsRecommendations');

const day = 86400;

class SettingsRecommendationsService {
  private storageService: StorageService;

  private nativeMessageService: NativeMessageService;

  constructor({ storageService, nativeMessageService }) {
    this.storageService = storageService;
    this.nativeMessageService = nativeMessageService;
  }

  isLuxDriver = false;

  getRecommendedMinPayout = () => {
    return this.isLuxDriver ? 30 : 20;
  };

  getRecommendedRadius = () => {
    return 15;
  };

  getFilters = async () => {
    const filters = await api.settings.get();
    // @ts-ignore
    const { min_dist, ride_types, radius } = filters;

    return {
      minDist: min_dist,
      radius,
      isLuxDriver: this.checkIsLuxDriver(ride_types),
    };
  };

  checkIsLuxDriver = (rideTypes: string) => {
    if (rideTypes === '') {
      return false;
    }

    return rideTypes !== 'Lyft' && !rideTypes.includes('Lyft,');
  };

  checkIsGettingRides = async () => {
    const timestamp = Timer.now();
    const dayAgo = timestamp - day;
    const creditsResponse = await api.purchases.list({ timestamp: timestamp - 2 * day });
    // @ts-ignore
    const credits = creditsResponse?.items || [];

    if (credits.length) {
      return false;
    }

    const hasCompletedRides =
      credits.findIndex((credit: Credit) => {
        return credit.event_type === 2;
      }) !== -1;

    const hasRidesInLastDay =
      credits.findIndex((credit: Credit) => {
        return credit.timestamp >= dayAgo;
      }) !== -1;

    return hasCompletedRides || hasRidesInLastDay;
  };

  getData = async ({
    isSearchOn,
    permissions,
  }: RunParams): Promise<Partial<SettingsRecommendationsMap>> => {
    const data: Partial<SettingsRecommendationsMap> = {};

    debug('permissions =', permissions);

    if (!isSearchOn) {
      data.search = true;
    }

    const { isLuxDriver, ...filters } = await this.getFilters();

    debug({ isLuxDriver, filters });

    this.isLuxDriver = isLuxDriver;
    const settingsTime = config.getItem('recommendationsTime');

    if (
      !Timer.isTimeLeft({
        key: StorageKeys.recommendationServiceStartedAt,
        inTime: settingsTime.started * day,
      })
    ) {
      debug("Started less than 2 days, doesn't run getting rides history");
      return data;
    }

    const isGettingRides = await this.checkIsGettingRides();

    debug('isGettingRides =', isGettingRides);

    if (isGettingRides) {
      return data;
    }

    debug(
      'maxMilesToPickupRecommendation',
      'filters.radius > this.getRecommendedRadius() =',
      filters.radius > this.getRecommendedRadius()
    );

    if (filters.radius > this.getRecommendedRadius()) {
      data.maxMilesToPickup = true;
    }

    debug(
      'minimumRidePayout',
      'filters.minDist > this.getRecommendedMinPayout() =',
      filters.minDist > this.getRecommendedMinPayout()
    );

    if (filters.minDist > this.getRecommendedMinPayout()) {
      data.minimumRidePayout = true;
    }

    if (
      config.isFeatureEnabled('locationPermissionRequest') &&
      permissions.location !== PermissionStatus.Undefined &&
      permissions.location !== 2
    ) {
      data.locationPermission = true;
    }

    if (
      config.isFeatureEnabled('notificationPermissionRequest') &&
      permissions.notification !== PermissionStatus.Undefined &&
      permissions.notification !== 2
    ) {
      data.pushPermission = true;
    }

    return data;
  };

  askForPermission(permission: RequestPermissionMessage['value']) {
    debug(`askForPermission for "${permission}"`);
    this.nativeMessageService.sendRequestPermissionMessage(permission);
  }

  run = async ({ isSearchOn, permissions }: RunParams) => {
    try {
      const data = await this.getData({ isSearchOn, permissions });
      debug('data =', data);

      if (Object.keys(data).length > 0) {
        return data;
      }

      return {};
    } catch (e) {
      console.log(e);
      return {};
    }
  };
}

export const settingsRecommendationsService = new SettingsRecommendationsService({
  storageService,
  nativeMessageService,
});

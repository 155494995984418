import { isIos } from '../helpers/os';

export enum StorageKeys {
  apiUrl = 'apiUrl',
  notificationAuthToken = 'notificationAuthToken',
  deviceToken = 'deviceToken',
  clientHeaders = 'clientHeaders',
  bestRides = 'bestRides',
  destinationTimes = 'destinationTimes',
  isMobileApp = 'isMobileApp',
  inAppReviewNextDate = 'inAppReviewNextDate',
  inAppNativeReviewShown = 'inAppNativeReviewShown',
  validationItems = 'validationItems',
  isRegistered = 'isRegistered',
  firstShowedNewRides = 'firstShowedNewRides',
  showMultiPinsModal = 'showMultiPinsModal1',
  showNotAllowedSearchModal = 'showNotAllowedSearchModal',
  registrationData = 'registrationData',
  showRegistrationTip = 'showRegistrationTip',
  appVersion = 'appVersion',
  recommendationServiceStartedAt = 'recommendationServiceStartedAt',
  serviceRecommendationTime = 'serviceRecommendationTime',
}

interface RegistrationData {
  phone_number: string;
  license_number: string;
}

interface ClientHeaders {
  'X-Os': string;
  'X-Session': string;
  'X-Client-Session-Id': string;
  'X-Os-Version': string;
  'X-Hardware': string;
  'X-App-Version': string;
  'X-Language': string;
  'X-Carrier': string;
}

interface Session {
  /*
    deviceId
   */
  a: string;

  f: string;

  h: boolean;
  /*
    accountIdentifierToken
   */
  k: string;
}

export class StorageService {
  setItem = (key: StorageKeys, value: string) => {
    localStorage.setItem(key, value);
    window.dispatchEvent(new Event('storage'));
  };

  getItem = (key: StorageKeys): string | null => {
    return localStorage.getItem(key);
  };

  removeItem = (key: StorageKeys) => {
    localStorage.removeItem(key);
  };

  has = (key: string): boolean => {
    return localStorage.getItem(key) !== null;
  };

  clear = () => {
    this.removeItem(StorageKeys.notificationAuthToken);
    this.removeItem(StorageKeys.bestRides);
  };

  setRegistrationData = ({ phone_number, license_number }: RegistrationData) => {
    this.setItem(
      StorageKeys.registrationData,
      JSON.stringify({
        phone_number: phone_number.slice(2),
        license_number,
      })
    );
  };

  getRegistrationData = (): RegistrationData => {
    const rowRegistrationData = this.getItem(StorageKeys.registrationData);
    const { phone_number, license_number } = rowRegistrationData
      ? JSON.parse(rowRegistrationData)
      : { phone_number: '', license_number: '' };

    return { phone_number, license_number };
  };

  getSession = (): Session | null => {
    if (isIos) {
      return null;
    }

    try {
      const clientHeaders: ClientHeaders = JSON.parse(
        this.getItem(StorageKeys.clientHeaders) as string
      );
      return JSON.parse(atob(clientHeaders['X-Session'])) as Session;
    } catch (err) {
      return null;
    }
  };

  getDeviceId = (): string | null => {
    if (isIos) {
      return null;
    }

    return this.getSession()?.a || null;
  };

  getAccountIdentifierToken = (): string | null => {
    if (isIos) {
      return null;
    }

    return this.getSession()?.k || null;
  };

  getAppVersion = (): string | null => {
    const params = this.getItem(StorageKeys.clientHeaders);

    if (!params) {
      return null;
    }

    const { 'X-App-Version': appVersion } = JSON.parse(params);

    return appVersion || null;
  };
}

export const storageService = new StorageService();

export default storageService;

import React, { useLayoutEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { AppState } from '../../store/interfaces';
import { Carousel } from '../../common/Carousel';
import { getSettings } from '../../store/settings/actions';
import urls from '../../config/urls';
import { CloseButton } from '../../common/CloseButton';
import { setWizardStep } from '../../store/app/acions';

import { Intro } from './steps/Intro';
import { BeginningOfWork } from './steps/BeginningOfWork';
import { WorkingTime } from './steps/WorkingTime';
import { WorkingDays } from './steps/WorkingDays';
import { WizardSteps } from './interfaces';
import { useSetNextWizardStep } from './hooks';
import { MinimumRidePayout } from './steps/MinimumRidePayout';
import { MaxMilesToPickUp } from './steps/MaxMilesToPickUp';
import { PickUpOutsideState } from './steps/PickUpOutsideState';
import { DropOffOutsideState } from './steps/DropOffOutsideState';
import { AllDone } from './steps/AllDone';

const wizardSteps: WizardSteps = new Map([
  ['intro', Intro],
  ['beginningOfWork', BeginningOfWork],
  ['workingTime', WorkingTime],
  ['workingDays', WorkingDays],
  ['minimumRidePayout', MinimumRidePayout],
  ['maxMilesToPickUp', MaxMilesToPickUp],
  /*
  ['pickUpOutsideState', PickUpOutsideState],
  ['dropOffOutsideState', DropOffOutsideState],
   */
  ['allDone', AllDone],
]);

export const Wizard = React.memo(function () {
  const wizardStep = useSelector((app: AppState) => app.app.wizardStep, shallowEqual);
  const setNextWizardStep = useSetNextWizardStep(wizardSteps);
  const dispatch = useDispatch();
  const begin_work = useSelector(
    (app: AppState) => app.settings.daySettings.begin_work,
    shallowEqual
  );

  useLayoutEffect(() => {
    if ([null, 'allDone'].includes(wizardStep)) {
      return;
    }

    if (begin_work === null) {
      dispatch(getSettings.request());
    }
  }, []);

  const location = useLocation();

  const StepComponent = wizardSteps.get(wizardStep);

  if (StepComponent) {
    return (
      <Carousel>
        {location.pathname === urls.settings && (
          <CloseButton onClick={() => dispatch(setWizardStep(null))} />
        )}
        <StepComponent setNextWizardStep={setNextWizardStep} />
      </Carousel>
    );
  }

  return null;
});
